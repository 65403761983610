<template lang="pug">
modal(size='sm' @close='$emit("close")')
  generic-form#FormTagCreate(:endpoint='`/_/Projects/${id}/ProjectTags/*`' @submitSuccess='onSubmitSuccess')
    template(#default='{ config }')
      form-header
        span(v-html='config.label')
      form-input(name='TagName' :autofocus='true')
      form-footer
        form-action(:primary='true' name='AddTag')
</template>

<script lang="ts">
import { useProjectsStore } from '../store'
import { type IProjectData } from '../types'
import { type IFormResponseInfo } from '@/components/GenericForm/types'
import { defineComponent } from 'vue'

const FormProjectTagCreate = defineComponent({
  inheritAttrs: false,
  props: {
    id: { type: String, required: true },
  },
  setup(props, context) {
    const store = useProjectsStore()
    return {
      onSubmitSuccess: (e: IFormResponseInfo<IProjectData>) => {
        store.setProject(e.data)
        context.emit('close')
      },
    }
  },
})
export default FormProjectTagCreate
</script>
